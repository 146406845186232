<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div class="mat-typography" mat-dialog-content>
    <p *ngIf="data.description !== null">{{ data.description | translate }}</p>
    <form [formGroup]="form" class="flex flex-col">
        <sz-select formControlName="selected" [options]="options" />
    </form>
    <sz-dialog-button-bar
        primaryText="btn.apply"
        [primaryDisabled]="selected === null"
        (primaryClick)="dialogRef.close(selected)"
        (secondaryClick)="dialogRef.close()"
    />
</div>
